import { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import { gql, useLazyQuery } from "@apollo/client";
import { WikientryType } from "../../types";
import { FormattedWikiSection } from "./parseWiki";

const GET_WIKIENTRY = gql`
  query wikientry($searchTerm: String!) {
    wikientry(searchTerm: $searchTerm) {
      title
      uid
      lat
      lon
      status
      wikipageid
    }
  }
`;

export type Section = {
  title: string;
  index: number;
  text: string;
  loading: boolean;
};

type WikiAccordionProps = {
  introText: string;
  wikiPageName: string;
  onInternalLinkClicked: (wikientry: WikientryType) => void;
  onSectionExpanded: (expandedSections: string[]) => void;
  expandedSections: string[];
  wikiPageTextformatted: FormattedWikiSection[];
};

export default function WikiAccordion(props: WikiAccordionProps) {
  const [getWikiEntry, { data }] = useLazyQuery(GET_WIKIENTRY);

  useEffect(() => {
    if (data?.wikientry) {
      props.onInternalLinkClicked(data?.wikientry);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: props.introText,
        }}
      />

      {props.wikiPageTextformatted.map((section) => {
        return (
          <AccordionStyled
            key={section.sectionName}
            onChange={(event: React.SyntheticEvent, isExpanded: boolean) => {
              if (props.expandedSections.includes(section.sectionName)) {
                props.onSectionExpanded(
                  props.expandedSections.filter(
                    (index) => index !== section.sectionName
                  )
                );
              } else {
                props.onSectionExpanded([
                  ...props.expandedSections,
                  section.sectionName,
                ]);
              }
            }}
            expanded={props.expandedSections.includes(section.sectionName)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{section.sectionName.replace("_", " ")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: section.sectionText,
                }}
                onClick={(event) => {
                  const el = event.target;
                  //@ts-ignore
                  const url = el.getAttribute("href");
                  if (
                    url?.startsWith("/wiki/") &&
                    !url?.startsWith("/wiki/Wikivoyage")
                  ) {
                    const searchTerm = url.slice(6).replace("_", " ");
                    event.preventDefault();
                    getWikiEntry({
                      fetchPolicy: "no-cache",
                      variables: {
                        searchTerm,
                      },
                      notifyOnNetworkStatusChange: true,
                    });
                  }
                }}
              ></div>
            </AccordionDetails>
          </AccordionStyled>
        );
      })}
    </div>
  );
}

const AccordionStyled = styled(Accordion)`
  box-shadow: none;
`;
