const getPosition = function () {
    const options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0,
      };
      
    
    return new Promise<GeolocationPosition>(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  }

const getCurrentLocation = async () => {
    try {
        const pos = await getPosition();
        return pos;
    } catch(error: any) {
        console.error(error);
    }
  };

export default getCurrentLocation