import styled from "@emotion/styled";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ControlButton from "../../leaflet/ControlButton";

type FilterboxProps = {
  selectedStatuses: string[];
  onFilterClick: (clickedFilter: string[]) => void;
};

function Filterbox(props: FilterboxProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilterClick = (clickedFilter: string) => {
    if (props.selectedStatuses.includes(clickedFilter)) {
      const newFilterStatuses = props.selectedStatuses.filter(
        (filterStatus) => filterStatus !== clickedFilter
      );
      props.onFilterClick(newFilterStatuses);
    } else {
      const newFilterStatuses = [...props.selectedStatuses, clickedFilter];
      props.onFilterClick(newFilterStatuses);
    }
  };

  const filters = ["country", "region", "district", "city", "park"];
  return (
    <>
      <FilterButton aria-describedby={id} onClick={handleClick}>
        <FilterAltIcon sx={{ fontSize: 24 }} />
      </FilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <FilterTitle>Filters</FilterTitle>
        <FormGroup>
          {filters.map((filter) => (
            <FilterItem isSelected={props.selectedStatuses.includes(filter)}>
              <FormControlLabelStyled
                control={
                  <Checkbox
                    sx={{
                      "&": {
                        padding: 0.5,
                      },
                      "& .PrivateSwitchBase-input": {
                        fontSize: 14,
                      },
                      "& .MuiSvgIcon-root": { fontSize: 22, padding: 0 },
                    }}
                    checked={props.selectedStatuses.includes(filter)}
                    onChange={() => handleFilterClick(filter)}
                    inputProps={{ "aria-label": "controlled" }}
                    defaultChecked
                  />
                }
                label={filter}
              />
            </FilterItem>
          ))}
        </FormGroup>
      </Popover>
    </>
  );
}

const FilterButton = styled(ControlButton)`
  bottom: 200px;
`;

const FilterItem = styled.div<{ isSelected: boolean }>`
  color: ${(props) => (props.isSelected ? "#4495cf" : "lightgray")};
  margin-right: 10px;
  padding: 2px 5px;
  font-size: 14px;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  margin: 0;
`;

const FilterTitle = styled.h5`
  margin: 8px;
`;

export default Filterbox;
