import axios from "axios";

const BASE_URL = "https://en.wikivoyage.org/w/api.php";

const getPage = async (wikipageName:string) => {

  const params = 
  "?action=parse" +
  "&format=json" +
  "&origin=*" +
  "&prop=text" +
  "&page=" + wikipageName;

  const response = await axios({
    withCredentials: false,
    url: BASE_URL + params,
    // url: "https://en.wikipedia.org/w/api.php?action=opensearch&search=testing&origin=*&callback=",
    //url: "https://en.wikivoyage.org/w/api.php?action=opensearch&search=bangkok&origin=*&callback=",
  });

  return response.data;

}


const getPageSections = async (text: string) => {
  const params =
  "?action=parse" +
  "&format=json" +
  "&origin=*" +
  "&prop=sections" +
  "&page=" + text;

  const response = await axios({
    withCredentials: false,
    url: BASE_URL + params,
    // url: "https://en.wikipedia.org/w/api.php?action=opensearch&search=testing&origin=*&callback=",
    //url: "https://en.wikivoyage.org/w/api.php?action=opensearch&search=bangkok&origin=*&callback=",
  });

  return response.data;
}

const getPageSectionText = async (text: string, section: number) => {
  const params =
  "?action=parse" +
  "&format=json" +
  "&origin=*" +
  "&prop=text" +
  "&section=" + section +
  "&disabletoc=1" +
  "&page=" + text;

  const response = await axios({
    withCredentials: false,
    url: BASE_URL + params,
    // url: "https://en.wikipedia.org/w/api.php?action=opensearch&search=testing&origin=*&callback=",
    //url: "https://en.wikivoyage.org/w/api.php?action=opensearch&search=bangkok&origin=*&callback=",
  });

  return response.data;
}

const wikiQuery = async (text: string) => {
  // const wikiParams =
  // "?action=parse" +
  // "&format=json" +
  // "&origin=*" +
  // // "&explaintext=true" +
  // "&prop=subtitle" +
  // "&page=" +
  // text;

  // "&pageid=" +
  // pageId;

  const wikiParams =
  "?action=query" +
  "&format=json" +
  "&origin=*" +
  // "&explaintext=true" +
  "&prop=extracts" +
  "&exintro=1" +
  "&titles=" +
  text;

  const response = await axios({
    withCredentials: false,
    url: BASE_URL + wikiParams,
    // url: "https://en.wikipedia.org/w/api.php?action=opensearch&search=testing&origin=*&callback=",
    //url: "https://en.wikivoyage.org/w/api.php?action=opensearch&search=bangkok&origin=*&callback=",
  });

  return response.data;
};


export {wikiQuery, getPageSections, getPageSectionText, getPage};
