import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";

type DrawerTopMenubarProps = {
  selectedTab: string;
  onTabSelected: (selectedTab: string) => void;
  selectedWikiPage: string | undefined;
};

//
function DrawerTopMenubar(props: DrawerTopMenubarProps) {
  const handleChange = (event: React.SyntheticEvent, selectedTab: string) => {
    props.onTabSelected(selectedTab);
  };

  return (
    <Root>
      <TabsStyled
        value={props.selectedTab}
        onChange={handleChange}
        aria-label="secondary tabs example"
      >
        <TabStyled value="wiki" label="Wikivoyage" />
        <TabStyled value="gemini" label="ASK AI" />
        <TabStyled value="user" label="UserPage" />
      </TabsStyled>
    </Root>
  );
}

const Root = styled(Box)`
  width: 100%;
`;

const TabsStyled = styled(Tabs)`
  min-height: 40px;
  pointer-events: all;
`;

const TabStyled = styled(Tab)`
  min-height: 40px;
`;

export default DrawerTopMenubar;
