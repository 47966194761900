import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useLazyQuery } from "@apollo/client";
import styled from "@emotion/styled";
import { WikientryType } from "../types";
import { GET_AUTOCOMPLETELIST } from "../../api/searchBar.gql";

type SearchbarProps = {
  value: string | null;
  onSearchComplete: (wikientry: WikientryType) => void;
};

export default function Searchbar(props: SearchbarProps) {
  const [getWikiEntries, { data: wikiEntries, loading }] =
    useLazyQuery(GET_AUTOCOMPLETELIST);

  const handleOnChange = (event: React.SyntheticEvent, value: string) => {
    const searchTerm = value;
    if (searchTerm.length > 2) {
      getWikiEntries({
        fetchPolicy: "no-cache",
        variables: {
          searchTerm,
        },
        notifyOnNetworkStatusChange: true,
      });
    }
  };

  return (
    <Card>
      <Autocomplete
        filterOptions={(x) => x}
        id="combo-box-demo"
        options={
          wikiEntries?.wikientriesAutocomplete.map(
            (entry: WikientryType) => entry.title
          ) || []
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        //@ts-ignore
        value={props.value}
        disableClearable
        noOptionsText="No locations"
        onChange={(event: React.SyntheticEvent, newValue: string | null) => {
          const wikiEntry = wikiEntries?.wikientriesAutocomplete.find(
            (entry: WikientryType) => entry.title === newValue
          );
          if (wikiEntry) props.onSearchComplete(wikiEntry);
        }}
        onInputChange={handleOnChange}
        sx={{ width: 284, fontSize: 12 }}
        renderInput={(params) => (
          <TextFieldStyled
            {...params}
            label="Search"
            placeholder="Type location name"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Card>
  );
}

const TextFieldStyled = styled(TextField)`
  & .MuiInputBase-input,
  & .MuiFormLabel-root {
    font-size: 16px;
  }
`;

const Card = styled.div`
  border: 1px solid #4495cf;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  border-radius: 5px;
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  ''width: 290px;
`;
