import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useMutation } from "@apollo/client";
import { LIKEPLACE, UNLIKEPLACE } from "../../api/user.gql";
import { Traveller } from "../types";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import Fade from "@mui/material/Fade";

type Props = {
  wikientryUid: string | null;
  currentUser?: Traveller | undefined;
  onPlaceLiked: () => void;
};

export default function LikePlace(props: Props) {
  const [likePlace, { loading: likeLoading }] = useMutation(LIKEPLACE, {
    fetchPolicy: "no-cache",
  });

  const [unlikePlace, { loading: unlikeLoading }] = useMutation(UNLIKEPLACE, {
    fetchPolicy: "no-cache",
  });

  const isLiked = props.currentUser?.likedPlaces?.find(
    (place) => place.uid === props.wikientryUid
  );

  const handleLikeClicked = async () => {
    if (props.wikientryUid) {
      if (isLiked) {
        await unlikePlace({
          variables: {
            wikientryUid: props.wikientryUid,
          },
        });
      } else {
        await likePlace({
          variables: {
            wikientryUid: props.wikientryUid,
          },
        });
      }

      props.onPlaceLiked();
    }
  };

  const [openTooltip, setopenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setopenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setopenTooltip(true);
  };

  return props.currentUser?.username !== "None" &&
    props.wikientryUid !== null ? (
    <>
      <ButtonStyled onClick={handleLikeClicked}>
        {likeLoading || unlikeLoading ? (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: -5,
              right: 18,
              zIndex: 1,
            }}
          />
        ) : isLiked ? (
          <BookmarkIcon sx={{ fontSize: 24 }} />
        ) : (
          <BookmarkBorderIcon sx={{ fontSize: 24 }} />
        )}
      </ButtonStyled>
    </>
  ) : (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title='please Login to enable "bookmark location" feature'
      onClose={handleTooltipClose}
      open={openTooltip}
    >
      <ButtonStyled onClick={handleTooltipOpen}>
        <BookmarkBorderIcon sx={{ fontSize: 24 }} color="disabled" />
      </ButtonStyled>
    </Tooltip>
  );
}

const ButtonStyled = styled(Button)`
  padding: 0;
`;
