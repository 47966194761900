import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import LikePlace from "./LikePlace";
import StartViewPoint from "./StartViewPoint";
//import AddToTrip from "./addToTrip/AddToTrip";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import ParkIcon from "@mui/icons-material/Park";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import LanguageIcon from "@mui/icons-material/Language";
import type { Poi } from "./../../leaflet/MapViewport";
import type { Traveller } from "../types";

type Props = {
  selectedMarker: Poi | null;
  currentUser: Traveller | undefined;
  onRefetchCall: () => void;
  selectedTab: string;
};

function DrawerTitle(props: Props) {
  return props.selectedTab === "wiki" ? (
    <Title>
      <Typography sx={{ color: "text.secondary" }}>
        {props.selectedMarker?.name ? (
          <MarkerNameWrapper>
            <LocationIconWrapper>
              {props.selectedMarker?.status.includes("city") ? (
                <LocationCityIcon sx={{ fontSize: 24 }} />
              ) : props.selectedMarker?.status.includes("district") ? (
                <MapIcon sx={{ fontSize: 24 }} />
              ) : props.selectedMarker?.status.includes("park") ? (
                <ParkIcon sx={{ fontSize: 24 }} />
              ) : props.selectedMarker?.status.includes("region") ? (
                <TravelExploreIcon sx={{ fontSize: 24 }} />
              ) : props.selectedMarker?.status.includes("country") ? (
                <LanguageIcon sx={{ fontSize: 24 }} />
              ) : null}
              <SelectedMarkerName>
                {props.selectedMarker?.name}
              </SelectedMarkerName>
            </LocationIconWrapper>
            <div>
              <StartViewPoint
                wikientryUid={props.selectedMarker.key}
                currentUser={props.currentUser}
                onSetStartPoint={props.onRefetchCall}
              />
              <LikePlace
                wikientryUid={props.selectedMarker.key}
                currentUser={props.currentUser}
                onPlaceLiked={props.onRefetchCall}
              />
              {/* <AddToTrip
                wikientryUid={props.selectedMarker.key}
                currentUser={props.currentUser}
                onAddTrip={() => {}}
              /> */}
            </div>
          </MarkerNameWrapper>
        ) : (
          <MarkerNameWrapper>
            <LocationIconWrapper>
              <NotListedLocationIcon sx={{ fontSize: 24 }} />
              <SelectedMarkerName>no location</SelectedMarkerName>
            </LocationIconWrapper>
            <div>
              <StartViewPoint
                wikientryUid={null}
                currentUser={props.currentUser}
                onSetStartPoint={props.onRefetchCall}
              />
              <LikePlace
                wikientryUid={null}
                currentUser={props.currentUser}
                onPlaceLiked={props.onRefetchCall}
              />
              {/* <AddToTrip
                wikientryUid={null}
                currentUser={props.currentUser}
                onAddTrip={() => {}}
              /> */}
            </div>
          </MarkerNameWrapper>
        )}
      </Typography>
    </Title>
  ) : null;
}

export default DrawerTitle;

const MarkerNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
`;

const LocationIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedMarkerName = styled.div`
  margin-left: 8px;
  font-size: 16px;
  white-space: nowrap;
  width: 180px;
  overflow: hidden visible;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  padding: 15px;
`;
