//import { gql } from "@apollo/client";
import { gql } from "../__generated__";

const GET_WIKI_ENTRIES = gql(/* GraphQL */ `
  query Wikisbyviewport(
    $min_lon: Float!
    $max_lon: Float!
    $min_lat: Float!
    $max_lat: Float!
    $zoom: Int!
    $statusByFilter: StatusByFilter
  ) {
    wikisbyviewport(
      input: {
        minLat: $min_lat
        maxLat: $max_lat
        minLon: $min_lon
        maxLon: $max_lon
        zoom: $zoom
        statusByFilter: $statusByFilter
      }
    ) {
      uid
      title
      status
      lat
      lon
      wikipageid
    }
  }
`);

export {GET_WIKI_ENTRIES};