/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query askGemini($question: String!) {\n    askGemini(question: $question) {\n      answer\n    }\n  }\n": types.AskGeminiDocument,
    "\n  query Wikisbyviewport(\n    $min_lon: Float!\n    $max_lon: Float!\n    $min_lat: Float!\n    $max_lat: Float!\n    $zoom: Int!\n    $statusByFilter: StatusByFilter\n  ) {\n    wikisbyviewport(\n      input: {\n        minLat: $min_lat\n        maxLat: $max_lat\n        minLon: $min_lon\n        maxLon: $max_lon\n        zoom: $zoom\n        statusByFilter: $statusByFilter\n      }\n    ) {\n      uid\n      title\n      status\n      lat\n      lon\n      wikipageid\n    }\n  }\n": types.WikisbyviewportDocument,
    "\n  query wikientriesAutocomplete($searchTerm: String!) {\n    wikientriesAutocomplete(input: { searchTerm: $searchTerm }) {\n      title\n      uid\n      lat\n      lon\n      status\n      wikipageid\n    }\n  }\n": types.WikientriesAutocompleteDocument,
    "\n  query isAuth {\n    isAuth\n  }\n": types.IsAuthDocument,
    "\n  query login($username: String!, $password: String!) {\n    login(username: $username, password: $password)\n  }\n": types.LoginDocument,
    "\n  query logout {\n    logout\n  }\n": types.LogoutDocument,
    "\n  mutation createTraveller(\n    $username: String!\n    $email: String!\n    $password: String!\n  ) {\n    createTraveller(username: $username, email: $email, password: $password)\n  }\n": types.CreateTravellerDocument,
    "\n  query currentUser {\n    currentUser {\n      uid\n      username\n      likedPlaces {\n        uid\n        wikipageid\n        title\n        lat\n        lon\n        zoom\n        status\n        ispartof\n      }\n      startViewpoint {\n        uid\n        wikipageid\n        title\n        lat\n        lon\n        zoom\n        status\n        ispartof\n      }\n      trips {\n        uid\n        name\n      }\n    }\n  }\n": types.CurrentUserDocument,
    "\n  mutation likePlace($wikientryUid: String!) {\n    likePlace(wikientryUid: $wikientryUid)\n  }\n": types.LikePlaceDocument,
    "\n  mutation unlikePlace($wikientryUid: String!) {\n    unlikePlace(wikientryUid: $wikientryUid)\n  }\n": types.UnlikePlaceDocument,
    "\n  mutation setStartviewpoint($wikientryUid: String!) {\n    setStartviewpoint(wikientryUid: $wikientryUid)\n  }\n": types.SetStartviewpointDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query askGemini($question: String!) {\n    askGemini(question: $question) {\n      answer\n    }\n  }\n"): (typeof documents)["\n  query askGemini($question: String!) {\n    askGemini(question: $question) {\n      answer\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Wikisbyviewport(\n    $min_lon: Float!\n    $max_lon: Float!\n    $min_lat: Float!\n    $max_lat: Float!\n    $zoom: Int!\n    $statusByFilter: StatusByFilter\n  ) {\n    wikisbyviewport(\n      input: {\n        minLat: $min_lat\n        maxLat: $max_lat\n        minLon: $min_lon\n        maxLon: $max_lon\n        zoom: $zoom\n        statusByFilter: $statusByFilter\n      }\n    ) {\n      uid\n      title\n      status\n      lat\n      lon\n      wikipageid\n    }\n  }\n"): (typeof documents)["\n  query Wikisbyviewport(\n    $min_lon: Float!\n    $max_lon: Float!\n    $min_lat: Float!\n    $max_lat: Float!\n    $zoom: Int!\n    $statusByFilter: StatusByFilter\n  ) {\n    wikisbyviewport(\n      input: {\n        minLat: $min_lat\n        maxLat: $max_lat\n        minLon: $min_lon\n        maxLon: $max_lon\n        zoom: $zoom\n        statusByFilter: $statusByFilter\n      }\n    ) {\n      uid\n      title\n      status\n      lat\n      lon\n      wikipageid\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query wikientriesAutocomplete($searchTerm: String!) {\n    wikientriesAutocomplete(input: { searchTerm: $searchTerm }) {\n      title\n      uid\n      lat\n      lon\n      status\n      wikipageid\n    }\n  }\n"): (typeof documents)["\n  query wikientriesAutocomplete($searchTerm: String!) {\n    wikientriesAutocomplete(input: { searchTerm: $searchTerm }) {\n      title\n      uid\n      lat\n      lon\n      status\n      wikipageid\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query isAuth {\n    isAuth\n  }\n"): (typeof documents)["\n  query isAuth {\n    isAuth\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query login($username: String!, $password: String!) {\n    login(username: $username, password: $password)\n  }\n"): (typeof documents)["\n  query login($username: String!, $password: String!) {\n    login(username: $username, password: $password)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query logout {\n    logout\n  }\n"): (typeof documents)["\n  query logout {\n    logout\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createTraveller(\n    $username: String!\n    $email: String!\n    $password: String!\n  ) {\n    createTraveller(username: $username, email: $email, password: $password)\n  }\n"): (typeof documents)["\n  mutation createTraveller(\n    $username: String!\n    $email: String!\n    $password: String!\n  ) {\n    createTraveller(username: $username, email: $email, password: $password)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query currentUser {\n    currentUser {\n      uid\n      username\n      likedPlaces {\n        uid\n        wikipageid\n        title\n        lat\n        lon\n        zoom\n        status\n        ispartof\n      }\n      startViewpoint {\n        uid\n        wikipageid\n        title\n        lat\n        lon\n        zoom\n        status\n        ispartof\n      }\n      trips {\n        uid\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query currentUser {\n    currentUser {\n      uid\n      username\n      likedPlaces {\n        uid\n        wikipageid\n        title\n        lat\n        lon\n        zoom\n        status\n        ispartof\n      }\n      startViewpoint {\n        uid\n        wikipageid\n        title\n        lat\n        lon\n        zoom\n        status\n        ispartof\n      }\n      trips {\n        uid\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation likePlace($wikientryUid: String!) {\n    likePlace(wikientryUid: $wikientryUid)\n  }\n"): (typeof documents)["\n  mutation likePlace($wikientryUid: String!) {\n    likePlace(wikientryUid: $wikientryUid)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation unlikePlace($wikientryUid: String!) {\n    unlikePlace(wikientryUid: $wikientryUid)\n  }\n"): (typeof documents)["\n  mutation unlikePlace($wikientryUid: String!) {\n    unlikePlace(wikientryUid: $wikientryUid)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation setStartviewpoint($wikientryUid: String!) {\n    setStartviewpoint(wikientryUid: $wikientryUid)\n  }\n"): (typeof documents)["\n  mutation setStartviewpoint($wikientryUid: String!) {\n    setStartviewpoint(wikientryUid: $wikientryUid)\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;