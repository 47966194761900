import { useEffect, } from "react";
import { formatWikipage } from "./parseWiki";
import { wikiQuery, getPageSections, getPage } from "../../../api/wikiQuery";
import WikiAccordion from "./WikiAccordion";
import type { Section } from "./WikiAccordion";
import { WikientryType } from "../../types";
import { FormattedWikiSection } from "./parseWiki";

type WikiQueryProps = {
  wikipageid: number;
  pageName: string;
  onSearchComplete: (wikientry: WikientryType) => void;
  onSectionExpanded: (expandedSections: string[]) => void;
  expandedSections: string[];
  formattedWikiPage: FormattedWikiSection[];
  onWikipageFetch: (formattedWikiPage: FormattedWikiSection[]) => void;
  introText: string;
  onFetchIntroText: (introText: string) => void;
};

export type DisplayedWikiSections = {
  section: Section;
  isExpanded: boolean;
};

function WikiPage(props: WikiQueryProps) {
  // const [introText, setIntroText] = useState("");

  useEffect(() => {
    const fetchSection = async () => {
      const wikiIntro = await wikiQuery(props.pageName);
      // setIntroText(wikiIntro.query.pages[props.wikipageid].extract);
      props.onFetchIntroText(wikiIntro.query.pages[props.wikipageid].extract);
    };

    const fetchPageSections = async () => {
      const sectionResponse = await getPageSections(props.pageName);
      const wikiPageResponse = await getPage(props.pageName);

      let sectionlist: string[] = [];
      //@ts-ignore
      sectionResponse.parse.sections.forEach((section) => {
        if (section.toclevel === 1)
          sectionlist.push(section.line.replace(" ", "_"));
      });

      const formattedWikiPage = formatWikipage(
        sectionlist,
        wikiPageResponse.parse.text["*"]
      );

      props.onWikipageFetch(formattedWikiPage);
    };

    if (props.pageName) {
      fetchPageSections();
      fetchSection();
      // fetchWikiPage();
    }
    // eslint-disable-next-line
  }, [props.pageName, props.wikipageid]);
  /*
  const handleChange = async (index: number) => {
    let newSections = sections.map((section) => {
      if (section.index === index) {
        return {
          ...section,
          loading: true,
        };
      } else {
        return section;
      }
    });
    setSections(newSections);
    const response = await getPageSectionText(props.pageName, index);
    const wikiText = response.parse?.text["*"];
    newSections = sections.map((section) => {
      if (section.index === index) {
        return {
          ...section,
          text: removeEdit(removeHeader(wikiText)),
          loading: false,
        };
      } else {
        return section;
      }
    });

    setSections(newSections);
  }; */

  const handleInternalLinkClicked = async (wikientry: WikientryType) => {
    const wikiIntro = await wikiQuery(wikientry.title);
    props.onFetchIntroText(wikiIntro.query.pages[wikientry.wikipageid].extract);
    // setIntroText(wikiIntro.query.pages[wikientry.wikipageid].extract);
    props.onSearchComplete(wikientry);
  };

  return (
    <>
      <WikiAccordion
        introText={props.introText}
        onInternalLinkClicked={handleInternalLinkClicked}
        wikiPageName={props.pageName}
        onSectionExpanded={props.onSectionExpanded}
        expandedSections={props.expandedSections}
        wikiPageTextformatted={props.formattedWikiPage}
      />
    </>
  );
}

export default WikiPage;
