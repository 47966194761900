const tilesConfig = {
    url: "https://maptiles.p.rapidapi.com/en/map/v1/{z}/{x}/{y}.png?rapidapi-key={'8ec96bb553msh87852ec9ccf3a88p1b4b4ejsnddfa2bd9b9d2'}",
    options: {
      attribution:
        '&copy; <a href="http://www.maptilesapi.com/">MapTiles API</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      maxZoom: 19,
    },
    headers: [
      {
        header: "X-RapidAPI-Key",
        value: "8ec96bb553msh87852ec9ccf3a88p1b4b4ejsnddfa2bd9b9d2",
      },
      { header: "X-RapidAPI-Hos", value: "maptiles.p.rapidapi.com" },
    ],
  };

  export default tilesConfig;