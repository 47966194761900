import styled from "@emotion/styled";
import WikiPage from "./wikipage/WikiPage";
import type { Poi } from "../../leaflet/MapViewport";
import { Traveller, WikientryType } from "../types";
import UserTab from "./userpage/UserTab";
import Gemini from "./gemini/Gemini";
import { ApolloError } from "@apollo/client";
import { ConversationItem } from "./gemini/Gemini";
import { FormattedWikiSection } from "./wikipage/parseWiki";

type Props = {
  selectedMarker: Poi | null;
  onSearchComplete: (wikientry: WikientryType) => void;
  currentUser: Traveller | undefined;
  onLogout: () => void;
  onLogin: () => void;
  currentUserLoading: Boolean;
  currentUserErr: ApolloError | undefined;
  onRefetchCall: () => void;
  tabToDisplay: string;
  onConversationChange: (conversation: ConversationItem[]) => void;
  geminiConversation: ConversationItem[];
  onSectionExpanded: (expandedSections: string[]) => void;
  expandedSections: string[];
  formattedWikiPage: FormattedWikiSection[];
  onWikipageFetch: (formattedWikiPage: FormattedWikiSection[]) => void;
  introText: string;
  onFetchIntroText: (introText: string) => void;
};

function DrawerTabs(props: Props) {
  return props.tabToDisplay === "wiki" ? (
    props.selectedMarker?.name ? (
      <WikiPage
        wikipageid={props.selectedMarker?.wikipageid}
        pageName={props.selectedMarker?.name}
        onSearchComplete={props.onSearchComplete}
        onSectionExpanded={props.onSectionExpanded}
        expandedSections={props.expandedSections}
        formattedWikiPage={props.formattedWikiPage}
        onWikipageFetch={props.onWikipageFetch}
        introText={props.introText}
        onFetchIntroText={props.onFetchIntroText}
      />
    ) : (
      <Hint>
        Please click a marker on the map in order to see the information.
      </Hint>
    )
  ) : props.tabToDisplay === "gemini" ? (
    <Gemini
      onConversationChange={props.onConversationChange}
      conversation={props.geminiConversation}
    />
  ) : (
    <UserTab
      currentUser={props.currentUser}
      loading={props.currentUserLoading}
      onLogin={props.onLogin}
      onLogout={props.onLogout}
      error={props.currentUserErr}
      onRefetchCall={props.onRefetchCall}
      onMarkedPlaceClicked={(place) => {
        props.onSearchComplete(place);
      }}
    />
  );
}

const Hint = styled.div`
  padding-top: 16px;
`;

export default DrawerTabs;
