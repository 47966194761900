import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { renderToString } from "react-dom/server";
import { useMapEvents, Marker, Popup } from "react-leaflet";
import L, { DivIcon } from "leaflet";
import { useLazyQuery } from "@apollo/client";
import Recenter from "./Recenter";
import Refetch from "./Refetch";
import Filterbox from "../modules/filterbox/Filterbox";
import tilesConfig from "./tilesConfig";
import { GET_WIKI_ENTRIES } from "../api/leaflet.gql";
import Searchbar from "../modules/searchBar/SearchBar";
import { WikientryType, Traveller } from "../modules/types";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonPinIcon from "@mui/icons-material/PersonPin";

type Props = {
  selectedMarker: Poi | null;
  onChangeSelectedMarker: (selectedhMarker: Poi | null) => void;
  searchMarker: WikientryType | null;
  onChangeSearchMarker: (searchMarker: WikientryType | null) => void;
  currentUser?: Traveller;
};

export type Poi = {
  key: string;
  name: string;
  location: number[];
  url: string;
  wikipageid: number;
  status: string;
};

type PositionCoord = [number, number];

const flyToZoomLevel = 9;

function MapViewport(props: Props) {
  const [filterStatuses, setFilterStatuses] = useState<string[]>([
    "city",
    "country",
    "region",
    "district",
    "park",
  ]);

  const handleFilterStatus = (clickedFilter: string[]) => {
    setFilterStatuses(clickedFilter);
    setViewportChanged(true);
  };

  const [positionMarker, setPositionMaker] = useState<PositionCoord | null>(
    null
  );
  const handleClickMarker = (marker: Poi) => {
    props.onChangeSelectedMarker(marker);
  };
  const [getWikiEntries, { data }] = useLazyQuery(GET_WIKI_ENTRIES);
  const [loading, setLoading] = useState(false);

  const map = useMapEvents({
    moveend: () => {
      setViewportChanged(true);
    },
  });

  useEffect(() => {
    L.tileLayer(
      tilesConfig.url,
      tilesConfig.options,
      //@ts-ignore
      tilesConfig.headers
    ).addTo(map);

    // L.control
    //   .zoom({
    //     position: "topright",
    //   })
    //   .addTo(map);

    handleLookClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  const handleLookClick = async () => {
    props.onChangeSearchMarker(null);
    const zoom = map.getZoom();
    const bounds = map.getBounds();

    setLoading(true);
    await getWikiEntries({
      fetchPolicy: "no-cache",
      variables: {
        min_lon: bounds.getWest(),
        max_lon: bounds.getEast(),
        min_lat: bounds.getSouth(),
        max_lat: bounds.getNorth(),
        zoom,
        statusByFilter: {
          city: filterStatuses.includes("city"),
          district: filterStatuses.includes("district"),
          region: filterStatuses.includes("region"),
          country: filterStatuses.includes("country"),
          park: filterStatuses.includes("park"),
        },
        //@ts-ignore
        notifyOnNetworkStatusChange: true,
      },
    });
    setViewportChanged(false);
    setLoading(false);
  };

  const handleRecenterClick = (latlon: [number, number]) => {
    map.flyTo([latlon[0], latlon[1]], flyToZoomLevel);

    setPositionMaker([latlon[0], latlon[1]]);
  };

  useEffect(() => {
    if (props.searchMarker) {
      map.flyTo(
        [props.searchMarker.lat, props.searchMarker.lon],
        flyToZoomLevel
      );
      handleClickMarker({
        key: props.searchMarker.uid,
        name: props.searchMarker.title,
        location: [props.searchMarker.lat, props.searchMarker.lon],
        url: getWikitravelUrl(props.searchMarker.title),
        wikipageid: props.searchMarker.wikipageid,
        status: props.searchMarker.status,
      });
    }
    //eslint-disable-next-line
  }, [props.searchMarker]);

  const getWikitravelUrl = (title: string) =>
    `https://wikitravel.org/en/${title.replace(" ", "_")}`;

  const markers = props.searchMarker
    ? [props.searchMarker]
    : data?.wikisbyviewport;

  const handleOnSearchComplete = (searchMarker: WikientryType) => {
    props.onChangeSearchMarker(searchMarker);
  };

  const likedPlaceMarkers = props.currentUser?.likedPlaces;

  const [viewportChanged, setViewportChanged] = useState(false);

  //[13.736717, 100.523186]
  return (
    (
      <>
        <Filterbox
          //onFilterClick={setFilterStatuses} //onFilterClick on child side returns string[]
          onFilterClick={handleFilterStatus}
          selectedStatuses={filterStatuses}
        />
        <Recenter onRecenterClick={handleRecenterClick} />
        <Refetch
          onRefetchClick={handleLookClick}
          loading={loading}
          showButton={viewportChanged}
        />

        {likedPlaceMarkers?.map(
          ({ uid, title, lat, lon, status, wikipageid }: WikientryType) => {
            const url = getWikitravelUrl(title);
            const isCity = status.includes("city");
            return (
              <Marker
                key={uid}
                position={[lat, lon]}
                icon={
                  new DivIcon({
                    html: renderToString(<WhereToVoteIcon fontSize="medium" />),
                    className: "map-marker-liked",
                    iconSize: [34, 34],
                    iconAnchor: [17, 34],
                  })
                }
                eventHandlers={{
                  click: (e) => {
                    handleClickMarker({
                      key: uid,
                      name: title,
                      location: [lat, lon],
                      url,
                      wikipageid,
                      status,
                    });
                  },
                }}
              >
                <Popup offset={isCity ? L.point(0, -40) : L.point(0, -18)}>
                  {title}
                </Popup>
              </Marker>
            );
          }
        )}

        {positionMarker ? (
          <Marker
            position={positionMarker}
            icon={
              new DivIcon({
                html: renderToString(<PersonPinIcon fontSize="medium" />),
                className: "map-marker-current",
                iconSize: [34, 34],
                iconAnchor: [17, 34],
              })
            }
          ></Marker>
        ) : null}

        <Searchbar
          onSearchComplete={handleOnSearchComplete}
          value={props.searchMarker?.title || null}
        />
        {markers?.map(
          ({ uid, title, lat, lon, status, wikipageid }: WikientryType) => {
            const url = getWikitravelUrl(title);
            const isCity = status.includes("city");
            const isLiked = !!props.currentUser?.likedPlaces?.find(
              (likedPlace) => likedPlace.uid === uid
            );
            return (
              !isLiked && (
                <Marker
                  key={uid}
                  position={[lat, lon]}
                  icon={
                    new DivIcon({
                      html: renderToString(
                        <LocationOnIconStyled fontSize="medium" />
                      ),
                      className: "map-marker",
                      iconSize: [34, 34],
                      iconAnchor: [17, 34],
                    })
                  }
                  eventHandlers={{
                    click: (e) => {
                      handleClickMarker({
                        key: uid,
                        name: title,
                        location: [lat, lon],
                        url,
                        wikipageid,
                        status,
                      });
                    },
                  }}
                >
                  <Popup offset={isCity ? L.point(0, -40) : L.point(0, -18)}>
                    {title}
                  </Popup>
                </Marker>
              )
            );
          }
        )}
      </>
    ) || null
  );
}

export default MapViewport;

const LocationOnIconStyled = styled(LocationOnIcon)`
  color: red;
`;
