


function removeEdit(textbody: string): string {
    
    const startpattern = '<span class="mw-editsection">'
    const endpattern = '<span class="mw-editsection-bracket">]</span></span>'    
    const boundaryStrings = new RegExp(`${startpattern}.*${endpattern}`, "g")

    return textbody.replaceAll(boundaryStrings, '')
}

function removeHeader(textbody: string): string {
    
    const startpattern = '<h2>'
    const endpattern = '</h2>'    
    const boundaryStrings = new RegExp(`${startpattern}.*${endpattern}`, "g")

    return textbody.replaceAll(boundaryStrings, '')
}

export type FormattedWikiSection = {
    sectionName: string
    sectionText: string 
}


function formatWikipage(sectionList: string[], wikiPageText: string): FormattedWikiSection[] {
    
    let payload: FormattedWikiSection[] = []
    // const startpattern = '<h2><span class="mw-headline" id="See">'
    const splitchunk = '<h2'
    const WikiPageSplitted = wikiPageText.split(splitchunk)

    sectionList.forEach( (sectionName) => {
        WikiPageSplitted.forEach( (WikiPageChunk)=>{ 
            if (WikiPageChunk.includes(` id="${sectionName}">`))
            {
                const wikiPageSectionText = WikiPageChunk.split("</h2>")
                payload.push({
                     sectionName : sectionName,
                     sectionText: removeEdit(wikiPageSectionText[1])
                })
            }
        } )

    })

    return payload

} 


export {removeEdit, removeHeader, formatWikipage}