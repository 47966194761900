import * as React from "react";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { REGISTER } from "../../../api/user.gql.ts";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  onRegister: () => void;
};

export default function Register(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [register, { data, loading, error }] = useMutation(REGISTER, {
    fetchPolicy: "no-cache",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRegister = async () => {
    await register({
      variables: {
        username: username,
        email: email,
        password: password,
      },
    });
  };

  useEffect(() => {
    if (data?.createTraveller) {
      handleClose();
      props.onRegister();
      setShowSuccess(true);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Register
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Register</DialogTitle>
        <DialogContent>
          <DialogContentText>Fill out the register form.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            margin="dense"
            id="pass"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {error ? (
            <AlertStyled severity="error">
              <AlertTitle>Registration failed</AlertTitle>
              {error.message}
            </AlertStyled>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRegister}>
            {loading ? (
              <Wrapper>
                <CircularProgress
                  size={25}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              </Wrapper>
            ) : (
              "Register"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          You are successfully registered now and can log in!
        </Alert>
      </Snackbar>
    </div>
  );
}

const AlertStyled = styled(Alert)`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 24px;
  width: 24px;
`;
