import styled from "@emotion/styled";
import { Traveller } from "../../types";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import LikePlace from "../LikePlace";
import Trips from "./Trips";
import { Wikientry } from "../../../__generated__/graphql";

type Props = {
  currentUser: Traveller | undefined;
  onLogout: () => void;
  onRefetchCall: () => void;
  onMarkedPlaceClicked: (place: Wikientry) => void;
};

export default function UserProfile(props: Props) {
  return (
    <>
      {props.currentUser && props.currentUser.username !== "None" ? (
        <Title>Welcome, Traveller {props.currentUser.username}!</Title>
      ) : null}

      <DividerStyled variant="middle" />

      <Subtitle>Your liked places:</Subtitle>

      {props.currentUser?.likedPlaces
        ? props.currentUser.likedPlaces.map((place: any) => {
            return (
              <>
                <Place
                  onClick={() => {
                    props.onMarkedPlaceClicked(place);
                  }}
                >
                  {place.title}{" "}
                  <LikePlace
                    wikientryUid={place.uid}
                    currentUser={props.currentUser}
                    onPlaceLiked={props.onRefetchCall}
                  />
                </Place>
              </>
            );
          })
        : null}

      <DividerStyled variant="middle" />

      <Trips />

      <DividerStyled variant="middle" />

      <Button variant="outlined" onClick={props.onLogout}>
        Logout
      </Button>
    </>
  );
}

const Title = styled.h3`
  margin: 24px 0 16px;
`;

export const Subtitle = styled.h5`
  margin: 0 0 8px;
`;

const DividerStyled = styled(Divider)`
  margin: 16px 0 16px;
`;

const Place = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
