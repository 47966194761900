import { gql } from "../__generated__";

const GET_AUTOCOMPLETELIST = gql(/* GraphQL */ `
  query wikientriesAutocomplete($searchTerm: String!) {
    wikientriesAutocomplete(input: { searchTerm: $searchTerm }) {
      title
      uid
      lat
      lon
      status
      wikipageid
    }
  }
`);

export {GET_AUTOCOMPLETELIST}