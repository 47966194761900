import { GET_GEMINIANSWER } from "../../../api/gemini.gql";
import { useLazyQuery } from "@apollo/client";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import Markdown from "react-markdown";
import { Button, Skeleton } from "@mui/material";

type Props = {
  onConversationChange: (conversation: ConversationItem[]) => void;
  conversation: ConversationItem[];
};

export type ConversationItem = {
  text: string;
  isResponse: boolean;
};

export default function Gemini(props: Props) {
  const [getGeminiAnswer, { data, loading }] = useLazyQuery(GET_GEMINIANSWER);

  const [questionText, setQuestionText] = useState<string>("");
  // const [conversation, setConversation] = useState<ConversationItem[]>([]);

  useEffect(() => {
    const answer = data?.askGemini.answer;
    if (answer) {
      // setConversation((conversation) => [
      //   ...conversation,
      //   {
      //     text: answer,
      //     isResponse: true,
      //   },
      // ]);

      props.onConversationChange([
        ...props.conversation,
        {
          text: answer,
          isResponse: true,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [props.conversation]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setQuestionText(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!questionText) return;

    // setConversation([
    //   ...props.conversation,
    //   {
    //     text: questionText,
    //     isResponse: false,
    //   },
    // ]);

    props.onConversationChange([
      ...props.conversation,
      {
        text: questionText,
        isResponse: false,
      },
    ]);

    getGeminiAnswer({
      fetchPolicy: "no-cache",
      variables: {
        question: questionText,
      },
      notifyOnNetworkStatusChange: true,
    });

    setQuestionText("");
  };

  return (
    <Wrapper>
      <AnswerScrollArea>
        <AnswerFieldStyled>
          {props.conversation.map((conversationItem, idx) => {
            const messageEl = (
              <div>
                <Markdown>{conversationItem.text}</Markdown>
              </div>
            );
            if (conversationItem.isResponse) {
              if (idx === props.conversation.length - 1) {
                return (
                  <ConversationResponse ref={messagesEndRef} key={idx}>
                    {messageEl}
                  </ConversationResponse>
                );
              } else {
                return (
                  <ConversationResponse key={idx}>
                    {messageEl}
                  </ConversationResponse>
                );
              }
            } else {
              if (idx === props.conversation.length - 1) {
                return (
                  <ConversationQuestion ref={messagesEndRef} key={idx}>
                    {messageEl}
                  </ConversationQuestion>
                );
              } else {
                return (
                  <ConversationQuestion key={idx}>
                    {messageEl}
                  </ConversationQuestion>
                );
              }
            }
          })}
          {loading && (
            <Loader>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Loader>
          )}
        </AnswerFieldStyled>
      </AnswerScrollArea>
      <form onSubmit={handleSubmit}>
        <TypeMsgWrapper>
          <QuestionFieldStyled
            id="GeminiQuestion"
            label="post your question"
            variant="outlined"
            value={questionText}
            onChange={handleChange}
          />
          <Button type="submit" variant="outlined">
            {loading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  bottom: 15,
                  right: 20,
                  zIndex: 1,
                }}
              />
            ) : (
              "Send"
            )}
          </Button>
        </TypeMsgWrapper>
      </form>
    </Wrapper>
  );
}

const QuestionFieldStyled = styled(TextField)`
  width: 100%;
  & .MuiInputBase-input,
  & .MuiFormLabel-root {
    font-size: 16px;
  }
`;

const AnswerScrollArea = styled.div`
  position: relative;
  height: calc(100% - 80px);
  overflow: scroll;
  margin: 10px 0;
  display: flex;
  flex-flow: column nowrap;
`;

const AnswerFieldStyled = styled.div`
  margin-top: auto;
  margin-right: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TypeMsgWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const Loader = styled.div`
  margin-top: 15px;
`;

const ConversationQuestion = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  > div {
    color: black;
    background-color: #e2f0ff;
    border-radius: 8px;
    padding: 0 10px;
    max-width: 85%;
  }
`;

const ConversationResponse = styled.div`
  display: flex;
  margin-top: 15px;

  > div {
    color: black;
    border-radius: 8px;
    background-color: #f0f0f0;
    padding: 0 10px;
    max-width: 85%;
  }
`;
