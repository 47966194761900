import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  onRefetchClick: () => void;
  loading: boolean;
  showButton: boolean;
};

function Refetch(props: Props) {
  return (
    <Root>
      <AnimatePresence>
        {props.showButton && (
          <motion.div
            key="searchInAreaButton"
            initial={{ opacity: 0, y: "50%", scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: "50%", transition: { duration: 0.3 } }}
            transition={{
              duration: 0.3,
            }}
          >
            <Card onClick={() => !props.loading && props.onRefetchClick()}>
              {props.loading ? (
                <CircularProgress size={24} />
              ) : (
                "Search this area"
              )}
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </Root>
  );
}

const Root = styled.div`
  bottom: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
`;

const Card = styled.div`
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background-color: white;
  color: #4495cf;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 22px;
  cursor: pointer;
  right: 10px;
  height: 24px;
  width: 150px;
`;

export default Refetch;
