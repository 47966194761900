import { useState } from "react";
import styled from "@emotion/styled";
import Map from "./leaflet/map";
import Drawer from "./modules/drawer/Drawer";
import type { Poi } from "./leaflet/MapViewport";
import { WikientryType } from "./modules/types";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "./api/user.gql";

export default function App() {
  const [selectedMarker, setSelectedMarker] = useState<Poi | null>(null);
  const [searchMarker, setSearchMarker] = useState<WikientryType | null>(null);

  const {
    data: currentUserData,
    refetch: refetchUser,
    error: currentUserErr,
    loading: currentUserLoading,
  } = useQuery(CURRENT_USER, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  const handleRefetch = async () => {
    await refetchUser();
  };

  return (
    <Wrapper>
      <Map
        selectedMarker={selectedMarker}
        searchMarker={searchMarker}
        onChangeSelectedMarker={(selectedMarker) =>
          setSelectedMarker(selectedMarker)
        }
        onChangeSearchMarker={(searchMarker) => setSearchMarker(searchMarker)}
        currentUser={currentUserData?.currentUser}
      />
      <Drawer
        selectedMarker={selectedMarker}
        onSearchComplete={(searchMarker) => setSearchMarker(searchMarker)}
        currentUser={currentUserData?.currentUser}
        onLogout={handleRefetch}
        onLogin={handleRefetch}
        currentUserLoading={currentUserLoading}
        currentUserErr={currentUserErr}
        onRefetchCall={handleRefetch}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
`;
