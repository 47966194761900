// import { TRIPS } from "../../../api/user.gql";
import { Subtitle } from "./UserProfile";
import { gql, useQuery } from "@apollo/client";

const TRIPS = gql(/* GraphQL */ `
  query trips {
    trips {
      uid
      name
      private
      travellers {
        uid
      }
      tripentries {
        uid
        wikipageid
        title
        lat
        lon
        zoom
        status
        ispartof
        order
      }
    }
  }
`);

export default function Trips() {
  const { data: TripsData } = useQuery(TRIPS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return (
    <>
      <Subtitle>Your trips:</Subtitle>
      {TripsData?.trips.map((trip: any) => {
        return (
          <>
            {trip.name}

            {trip.tripentries.length > 0 ? (
              trip.tripentries.map((location: any) => {
                return (
                  <>
                    <br />
                    <small>
                      {location.title} {location.order}
                    </small>
                  </>
                );
              })
            ) : (
              <>
                <br />
                <small>...empty</small>
              </>
            )}

            {/* {trip?.tripentries.map((location: any) => {
              return (
                <>
                  <br />
                  <small>{location.title}</small>
                </>
              );
            })} */}
            <br />
          </>
        );
      })}
    </>
  );
}
