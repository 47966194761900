import styled from "@emotion/styled";

const ControlButton = styled.div`
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  right: 10px;
  border: 1px solid #4495cf;
  height: 24px;
  width: 24px;
`;

export default ControlButton;
