import * as React from "react";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { LOGIN } from "../../../api/user.gql";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  onLogin: () => void;
};

export default function Login(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [login, { data, loading }] = useLazyQuery(LOGIN, {
    fetchPolicy: "no-cache",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = async () => {
    await login({
      variables: {
        username: username,
        password: password,
      },
    });
  };

  useEffect(() => {
    if (data?.login) {
      handleClose();
      props.onLogin();
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Login
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter your credentials.</DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            margin="dense"
            id="pass"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          {data && !data.login ? (
            <AlertStyled severity="error">
              <AlertTitle>Login failed</AlertTitle>
              Please, check your username and password
            </AlertStyled>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleLogin}>
            {loading ? (
              <Wrapper>
                <CircularProgress
                  size={25}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              </Wrapper>
            ) : (
              "Login"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const AlertStyled = styled(Alert)`
  margin-top: 20px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: 24px;
  width: 24px;
`;
