import { useLazyQuery, ApolloError } from "@apollo/client";
import styled from "@emotion/styled";
import { LOGOUT } from "../../../api/user.gql";
import Login from "./Login";
import Register from "./Register";
import UserProfile from "./UserProfile";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { Traveller } from "../../types";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Wikientry } from "../../../__generated__/graphql";

type Props = {
  currentUser: Traveller | undefined;
  loading: Boolean;
  error: ApolloError | undefined;
  onLogout: () => void;
  onLogin: () => void;
  onRefetchCall: () => void;
  onMarkedPlaceClicked: (place: Wikientry) => void;
};

export default function UserTab(props: Props) {
  const [logout] = useLazyQuery(LOGOUT, {
    fetchPolicy: "no-cache",
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const handleLogout = async () => {
    setShowLoader(true);
    await logout();
    props.onLogout();
    setShowLoader(false);
  };

  const handleLogin = () => {
    setShowSuccess(true);
    props.onLogin();
  };

  if (props.loading || showLoader) {
    return (
      <Center>
        <Wrapper>
          <CircularProgress
            size={50}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        </Wrapper>
      </Center>
    );
  }
  return props.currentUser && props.currentUser.username !== "None" ? (
    <>
      <UserProfile
        currentUser={props.currentUser}
        onLogout={handleLogout}
        onRefetchCall={props.onRefetchCall}
        onMarkedPlaceClicked={props.onMarkedPlaceClicked}
      />
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert
          onClose={() => setShowSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          You are successfully logged in
        </Alert>
      </Snackbar>
    </>
  ) : (
    <ButtonsWrapper>
      <Login onLogin={handleLogin} />
      <Register onRegister={() => {}} />
    </ButtonsWrapper>
  );
}

const Center = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
  height: 50px;
  width: 50px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  padding-top: 16px;

  > div {
    margin-right: 16px;
  }
`;
