import { gql } from "../__generated__";

const IS_AUTH = gql(/* GraphQL */ `
  query isAuth {
    isAuth
  }
`);

const LOGIN = gql(/* GraphQL */ `
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`);

const LOGOUT = gql(/* GraphQL */ `
  query logout {
    logout
  }
`);

const REGISTER = gql(/* GraphQL */ `
  mutation createTraveller(
    $username: String!
    $email: String!
    $password: String!
  ) {
    createTraveller(username: $username, email: $email, password: $password)
  }
`);

const CURRENT_USER = gql(/* GraphQL */ `
  query currentUser {
    currentUser {
      uid
      username
      likedPlaces {
        uid
        wikipageid
        title
        lat
        lon
        zoom
        status
        ispartof
      }
      startViewpoint {
        uid
        wikipageid
        title
        lat
        lon
        zoom
        status
        ispartof
      }
      trips {
        uid
        name
      }
    }
  }
`);

const LIKEPLACE = gql(/* GraphQL */ `
  mutation likePlace($wikientryUid: String!) {
    likePlace(wikientryUid: $wikientryUid)
  }
`);

const UNLIKEPLACE = gql(/* GraphQL */ `
  mutation unlikePlace($wikientryUid: String!) {
    unlikePlace(wikientryUid: $wikientryUid)
  }
`);

const SETSTARTVIEWPOINT = gql(/* GraphQL */ `
  mutation setStartviewpoint($wikientryUid: String!) {
    setStartviewpoint(wikientryUid: $wikientryUid)
  }
`);

// const TRIPS = gql(/* GraphQL */ `
// query trips {
//   trips{
//     uid
//     name
//     private
//     travellers {
//       uid
//     }
//     tripentries{
//       uid
//       wikipageid
//       title
//       lat
//       lon
//       zoom
//       status
//       ispartof
//       order
//     }
//   }
//   }
// `);

export {
  IS_AUTH,
  LOGIN,
  REGISTER,
  CURRENT_USER,
  LOGOUT,
  LIKEPLACE,
  UNLIKEPLACE,
  SETSTARTVIEWPOINT,
  // TRIPS
};
