import { useState } from "react";
import type { SyntheticEvent } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Global } from "@emotion/react";
import type { Poi } from "./../../leaflet/MapViewport";
import DrawerTopMenubar from "./DrawerTopMenuBar";
import type { Traveller, WikientryType } from "../types";
import { ApolloError } from "@apollo/client";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DrawerTabs from "./DrawerTabs";
import DrawerTitle from "./DrawerTitle";
import Highlight from "./Highlight";
import { ConversationItem } from "./gemini/Gemini";
import { FormattedWikiSection } from "./wikipage/parseWiki";

const drawerBleeding = 121;

type SwipeDirection = "right" | "left" | "up" | "down";
type TouchEvent = {
  xpos: number;
  ypos: number;
  time: number;
};

type Props = {
  selectedMarker: Poi | null;
  onSearchComplete: (wikientry: WikientryType) => void;
  currentUser: Traveller | undefined;
  onLogout: () => void;
  onLogin: () => void;
  currentUserLoading: Boolean;
  currentUserErr: ApolloError | undefined;
  onRefetchCall: () => void;
};

const headerHeight = 20;

function MapDrawer(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [tabToDisplay, setTabToDisplay] = useState("wiki");
  const [geminiConversation, setGeminiConversation] = useState<
    ConversationItem[]
  >([]);

  const [formattedWikiPage, setFormattedWikipage] = useState<
    FormattedWikiSection[]
  >([]);

  const [introText, setIntroText] = useState("");

  const handleFetchIntroText = (introText: string) => {
    setIntroText(introText);
  };

  const handleOnWikipageFetch = (formattedWikiPage: FormattedWikiSection[]) => {
    setFormattedWikipage(formattedWikiPage);
  };

  const handleConversationChange = (conversation: ConversationItem[]) => {
    setGeminiConversation(conversation);
  };

  const [expandedSections, setExpandedSection] = useState<string[]>([]);

  const handleOnSectionExpanded = (expandedSections: string[]) => {
    setExpandedSection(expandedSections);
  };

  const [prevPageName, setPrevPageName] = useState<string | undefined>(
    undefined
  );
  if (prevPageName !== props.selectedMarker?.name) {
    setPrevPageName(props.selectedMarker?.name);
    setExpandedSection([]);
  }

  const tabs = ["wiki", "gemini", "user"];

  const handleTabSelected = (selectedTab: string) => {
    setTabToDisplay(selectedTab);
  };

  const toggleDrawer =
    (isOpen: boolean) => (event: SyntheticEvent<{}, Event>) => {
      if (
        event &&
        event.type === "keydown" &&
        // @ts-ignore
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setIsOpen(isOpen);
    };

  const [touchStart, setTouchStart] = useState<TouchEvent>({
    xpos: 0,
    ypos: 0,
    time: 0,
  });

  const handleSwipe = (direction: SwipeDirection) => {
    const currentTab = tabs.indexOf(tabToDisplay);

    if (direction === "left" && currentTab < tabs.length - 1) {
      setTabToDisplay(tabs[currentTab + 1]);
    } else if (direction === "right" && currentTab > 0) {
      setTabToDisplay(tabs[currentTab - 1]);
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    const touchStartX = event.touches[0].screenX;
    const touchStartY = event.touches[0].screenY;

    setTouchStart({ xpos: touchStartX, ypos: touchStartY, time: Date.now() });
  };

  const handleTouchEnd = (event: React.TouchEvent) => {
    const touchEndX = event.changedTouches[0].screenX;
    const touchEndY = event.changedTouches[0].screenY;

    const swipeDirectionRationRef = 0.8;
    const swipeXdiffMin = 20;
    const swipeTimeSpan = Date.now() - touchStart.time;
    const xDiff = touchStart.xpos - touchEndX;

    const swipeDirectionRation = Math.abs(
      xDiff / (touchStart.ypos - touchEndY)
    );

    if (swipeTimeSpan < 300 && swipeDirectionRation > swipeDirectionRationRef) {
      if (xDiff < 0 && Math.abs(xDiff) > swipeXdiffMin) {
        handleSwipe("right");
      } else if (xDiff > 0 && Math.abs(xDiff) > swipeXdiffMin) {
        handleSwipe("left");
      }
    }
  };

  return (
    <>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(90% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            height: drawerBleeding,
          }}
        >
          <ArrowWrapper onClick={toggleDrawer(!isOpen)}>
            <KeyboardArrowUpIcon
              sx={{
                transform: isOpen ? "rotate(180deg)" : "rotate(0)",
                transition: "transform 362ms",
              }}
            />

            <Highlight selectedMarkerKey={props.selectedMarker?.key} />
          </ArrowWrapper>
          <DrawerTopMenubar
            onTabSelected={handleTabSelected}
            selectedTab={tabToDisplay}
            selectedWikiPage={props.selectedMarker?.name}
          />
          <DrawerTitle
            selectedMarker={props.selectedMarker}
            currentUser={props.currentUser}
            onRefetchCall={props.onRefetchCall}
            selectedTab={tabToDisplay}
          />
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            right: 0,
            left: 0,
            mt: `${headerHeight}px`,
            overflow: isOpen ? "auto" : "hidden",
            height: "100%",
          }}
        >
          {
            <DrawerTabs
              selectedMarker={props.selectedMarker}
              onSearchComplete={(wikientry) => {
                setIsOpen(false);
                props.onSearchComplete(wikientry);
              }}
              currentUser={props.currentUser}
              onLogout={props.onLogout}
              onLogin={props.onLogout}
              currentUserLoading={props.currentUserLoading}
              currentUserErr={props.currentUserErr}
              onRefetchCall={props.onRefetchCall}
              tabToDisplay={tabToDisplay}
              onConversationChange={handleConversationChange}
              geminiConversation={geminiConversation}
              onSectionExpanded={handleOnSectionExpanded}
              expandedSections={expandedSections}
              formattedWikiPage={formattedWikiPage}
              onWikipageFetch={handleOnWikipageFetch}
              introText={introText}
              onFetchIntroText={handleFetchIntroText}
            />
          }
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
}

const ArrowWrapper = styled.div`
  color: #1976d2;
  display: flex;
  justify-content: center;
`;

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
}));

export default MapDrawer;
