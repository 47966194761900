import { MapContainer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import MapViewport from "./MapViewport";
import type { Poi } from "./MapViewport";
import { WikientryType, Traveller } from "../modules/types";
import type { LatLngExpression } from "leaflet";

type Props = {
  selectedMarker: Poi | null;
  onChangeSelectedMarker: (selectedMarker: Poi | null) => void;
  searchMarker: WikientryType | null;
  onChangeSearchMarker: (searchMarker: WikientryType | null) => void;
  currentUser?: Traveller;
};

export default function Map(props: Props) {
  const Berlin = [52.520008, 13.404954] as LatLngExpression;

  return props.currentUser ? (
    <MapContainer
      center={
        props.currentUser?.startViewpoint
          ? [
              props.currentUser?.startViewpoint?.lat,
              props.currentUser?.startViewpoint?.lon,
            ]
          : Berlin
      }
      zoom={10}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "100%" }}
      zoomControl={false}
      touchZoom={true}
    >
      <MapViewport
        selectedMarker={props.selectedMarker}
        onChangeSelectedMarker={props.onChangeSelectedMarker}
        searchMarker={props.searchMarker}
        onChangeSearchMarker={props.onChangeSearchMarker}
        currentUser={props.currentUser}
      />
    </MapContainer>
  ) : null;
}
