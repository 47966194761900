import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { useMutation } from "@apollo/client";
import { Traveller } from "../types";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import CircularProgress from "@mui/material/CircularProgress";
import { SETSTARTVIEWPOINT } from "../../api/user.gql";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import Fade from "@mui/material/Fade";

type Props = {
  wikientryUid: string | null;
  currentUser?: Traveller | undefined;
  onSetStartPoint: () => void;
};

export default function StartViewPoint(props: Props) {
  const [setStartPoint, { loading }] = useMutation(SETSTARTVIEWPOINT, {
    fetchPolicy: "no-cache",
  });

  const [openTooltip, setopenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setopenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setopenTooltip(true);
  };

  const isSet = props.currentUser?.startViewpoint?.uid === props.wikientryUid;

  const handleSetStartPointClicked = async () => {
    if (props.wikientryUid) {
      await setStartPoint({
        variables: {
          wikientryUid: props.wikientryUid,
        },
      });
      props.onSetStartPoint();
    }
  };

  return props.currentUser?.username !== "None" &&
    props.wikientryUid !== null ? (
    <>
      <ButtonStyled onClick={handleSetStartPointClicked}>
        {loading ? (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: -5,
              right: 18,
              zIndex: 1,
            }}
          />
        ) : isSet ? (
          <CenterFocusStrongIcon sx={{ fontSize: 24 }} />
        ) : (
          <CenterFocusWeakIcon sx={{ fontSize: 24 }} />
        )}
      </ButtonStyled>
    </>
  ) : (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      title='Login and select a location to enable "start view location" feature'
      onClose={handleTooltipClose}
      open={openTooltip}
    >
      <ButtonStyled onClick={handleTooltipOpen}>
        <CenterFocusWeakIcon sx={{ fontSize: 24 }} color="disabled" />
      </ButtonStyled>
    </Tooltip>
  );
}

const ButtonStyled = styled(Button)`
  padding: 0;
`;
