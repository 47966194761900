import { useState } from "react";
import styled from "@emotion/styled";
import getCurrentLocation from "./getCurrentLocation";
import CircularProgress from "@mui/material/CircularProgress";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ControlButton from "./ControlButton";

type Props = {
  onRecenterClick: (latlon: [number, number]) => void;
};

function RecenterMap(props: Props) {
  const [loading, setLoading] = useState(false);
  const handleClickRecenterButton = async () => {
    setLoading(true);
    const locations = await getCurrentLocation();
    if (locations) {
      setLoading(false);
      props.onRecenterClick([
        locations.coords.latitude,
        locations.coords.longitude,
      ]);
    }
  };

  return (
    <RecenterButton>
      <Wrapper onClick={() => !loading && handleClickRecenterButton()}>
        {loading ? (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        ) : (
          <GpsFixedIcon />
        )}
      </Wrapper>
    </RecenterButton>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  > svg {
    height: 24px;
    width: 24px;
  }
`;

const RecenterButton = styled(ControlButton)`
  bottom: 150px;
`;
/*

const doSomething = (a) => {
  console.log(a)
  display a: 1 
}
 
const getA = (doSometing) => {
  callAPI(doSomethong);

  display a: undefined
  
}



*/

export default RecenterMap;
