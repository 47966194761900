import { gql } from "../__generated__";

const GET_GEMINIANSWER = gql(/* GraphQL */ `
  query askGemini($question: String!) {
    askGemini(question: $question) {
      answer
    }
  }
`);

export {GET_GEMINIANSWER}