import { motion } from "framer-motion";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

type Props = {
  selectedMarkerKey?: string;
};

const Highlight = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const variants = {
    open: {
      scale: [1, 1.999],
      opacity: [0.25, 0.0001],
    },
    closed: {
      scale: [1, 2],
      opacity: [0.25, 0],
    },
  };
  useEffect(() => {
    setIsOpen(!isOpen);
  }, [props.selectedMarkerKey]);
  return (
    <AnimatedDiv
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      transition={{ duration: 0.7 }}
    ></AnimatedDiv>
  );
};

const AnimatedDiv = styled(motion.div)`
  border: 1px solid;
  border-radius: 15px;
  background: #0085ff;
  opacity: 0.25;
  width: 30px;
  height: 30px;
  position: absolute;
`;

export default Highlight;
